import React from 'react'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import FingerPrint from 'components/elements/FingerPrint'

const FingerPrintWrapper = styled.div`
  & svg {
    & path {
      fill: ${(props) => props.theme.color.secondary};
    }
    width: 99px;
    height: 99px;
  }
`

const Usp = styled.a`
  background-color: ${(props) => props.theme.color.light};
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  transition: all 0.3s ease;

  & h3 {
    font-size: 50px;
  }

  & h4 {
    font-size: 25px;
  }

  &:hover {
    box-shadow: inset 0 0 0 4px ${({ theme }) => theme.color.secondary};
    cursor: pointer;

    & h3,
    & h4 {
      color: ${({ theme }) => theme.color.primary};
    }
  }

  @media screen and (max-width: 991px) {
    margin: 15px 0;
  }

  @media screen and (max-width: 768px) {
    margin: 15px 15px;
  }
`

interface UspsProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Usps
  location?: any
}

const Content = styled(ParseContent)`
  & h1,
  & h2,
  & h3 {
    /* font-weight: ${({ theme }) => theme.font.weight.bold}; */
  }
`

const Usps: React.FC<UspsProps> = ({ fields }) => (
  <section className="mb-lg-5 mb-2">
    <div className="container py-lg-5 py-3">
      <div className="row justify-content-center">
        <div className="col-12">
          <Content content={fields.description} />
        </div>
        <div className="row justify-content-between mt-5">
          {fields?.usp?.map((usp, index: number) => (
            <div
              className="col-lg-4 px-4 col-md-6 d-flex flex-grow-1"
              // eslint-disable-next-line react/no-array-index-key
              key={usp?.link?.title}
            >
              <Usp
                className="d-flex flex-column align-items-center p-5"
                href={usp?.link?.url}
                target="_blank"
                rel="noreferrer"
              >
                <FingerPrintWrapper className="position-relative">
                  <FingerPrint />
                </FingerPrintWrapper>
                <ParseContent content={usp?.description} />
              </Usp>
            </div>
          ))}
        </div>
      </div>
    </div>
  </section>
)

export default Usps
